body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.photo {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}
